import styled from 'styled-components';

import SelectComponent from '@components/common/selectInput/SelectInput';

import device from '@helpers/breakpoints';

// eslint-disable-next-line import/prefer-default-export
export const LanguageSelect = styled(SelectComponent)`
  width: 280px;

  @media ${device.tablet} {
    width: 80px;
  }
`;
