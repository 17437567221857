import React from 'react';

import { useServices } from '@packages/services';

import { isDesktopView } from '@helpers/baseUtils';

import { LANGUAGE } from '../../../constants/cookies';

import * as S from './DashboardLanguageSwitcher.styled';

const DashboardLanguageSwitcher = (): JSX.Element => {
  const { cookie } = useServices();
  const lang = cookie.get(LANGUAGE);
  const isDesktop = isDesktopView();

  const setLocale = (key): void => {
    cookie.set(LANGUAGE, key);
    document.location.reload();
  };

  return (
    <S.LanguageSelect
      defaultValue={lang || 'de-de'}
      onChange={setLocale}
      options={[
        {
          title: isDesktop ? 'English' : 'EN',
          value: 'en-us',
        },
        {
          title: isDesktop ? 'Deutsch' : 'DE',
          value: 'de-de',
        },
      ]}
    />
  );
};

export default DashboardLanguageSwitcher;
