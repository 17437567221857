import React from 'react';
import intl from 'react-intl-universal';

import HeaderBalanceLabel from '@components/common/balanceHeaderLabel/HeaderBalanceLabel';
import Icon from '@components/common/iconComponents/Icon';
import LogoTitle from '@components/common/aircashLogo/Logo';
import DashboardLanguageSwitcher from '@components/layout/dashboardLanguageSwitcher/DashboardLanguageSwitcher';
import RestrictedBlock from '@components/common/blockRestricted/RestrictedBlock';

import { isDesktopView } from '@helpers/baseUtils';

import { useApplication } from '@providers/applicationContext';
import toggleStatusSidebar from '@providers/applicationContext/actions/toggleStatusSidebar';

import UserTypes from '../../../constants/userTypes';

import * as S from './DashboardHeader.styled';

const { USER, PARTNER } = UserTypes;

const DashboardHeader = (): JSX.Element => {
  const { isMenuOpen } = useApplication();
  const isDesktop = isDesktopView();

  return (
    <S.HeaderContainer>
      {!(!isDesktop && isMenuOpen) && (
        <S.IconWrapper onClick={toggleStatusSidebar}>
          <Icon type={isMenuOpen ? 'hideMenu' : 'showMenu'} />
        </S.IconWrapper>
      )}

      <S.Inner>
        {!isMenuOpen && (
          <S.LogoWrapper>
            <LogoTitle />
          </S.LogoWrapper>
        )}
        <RestrictedBlock userRole={[PARTNER]}>
          <S.SupportWrapper>
            <span>
              {intl.get('common.support_hotline')}
              <a type="phone" href="tel:+4989215255567">
                089/215255567
              </a>
            </span>
          </S.SupportWrapper>
        </RestrictedBlock>

        <HeaderBalanceLabel />
      </S.Inner>

      <RestrictedBlock userRole={[USER]}>
        <DashboardLanguageSwitcher />
      </RestrictedBlock>

      {isMenuOpen && (
        <S.TranslucentBlock>
          <S.WhiteIconWrapper onClick={toggleStatusSidebar}>
            <Icon type={isMenuOpen ? 'hideMenu' : 'showMenu'} />
          </S.WhiteIconWrapper>
        </S.TranslucentBlock>
      )}
    </S.HeaderContainer>
  );
};

export default DashboardHeader;
