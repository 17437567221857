import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  height: calc(100vh - 88px);
  background-color: var(--surface-bg);
  font-family: Roboto;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 25px;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.33;
  color: var(--text);
`;

export const Message = styled.p`
  margin: 0 0 25px;
  font-size: 14px;
  line-height: 1.57;
  color: rgb(0 0 0 / 45%);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HomeButton = styled.div`
  margin-right: 10px;

  & button,
  & button:hover,
  & button:focus,
  & button:active {
    background-color: var(--primary-text-light);
    border: 1px solid var(--primary-text-light);
    color: var(--surface-bg);
    border-radius: 2px;
    box-shadow: 0 2px 0 0 rgb(0 0 0 / 4%);
  }
`;

export const BackButton = styled.div`
  & button,
  & button:hover,
  & button:focus,
  & button:active {
    background-color: var(--surface-bg);
    color: var(--text);
    border-radius: 2px;
    box-shadow: 0 2px 0 0 rgb(0 0 0 / 4%);
  }
`;
