import styled from 'styled-components';
import Button from 'antd/lib/button';

export const LabelHeader = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: #5b5b5b;
  margin-right: 10px;
`;

export const CreditButton = styled(Button)`
  margin-left: 20px;
  color: #1890ff;
  border-color: #1890ff;
`;
