import React from 'react';
import Button from 'antd/lib/button';

import { IBasicButton, ButtonType } from './BasicButton.types';
import * as S from './BasicButton.styled';

const BasicButton = (props: IBasicButton): JSX.Element => {
  const { disabled, className, onClick, children, type, htmlType, iconbtn } =
    props;

  return (
    <S.BasicButtonST iconbtn={iconbtn} type={type}>
      <Button
        className={className}
        disabled={disabled}
        onClick={onClick}
        htmlType={htmlType}
      >
        {children}
      </Button>
    </S.BasicButtonST>
  );
};

BasicButton.defaultProps = {
  iconbtn: false,
  type: ButtonType.yellow,
};

export default BasicButton;
