import React, { useEffect } from 'react';
import notification from 'antd/lib/notification';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isArray from 'lodash/isArray';

import { useNotification } from '@providers/notifications';
import deleteNotifications from '@providers/notifications/actions/deleteNotifications';
import { useApplication } from '@providers/applicationContext';

const Notifications = ({ children }): JSX.Element => {
  const notifications = useNotification();
  const { isMenuOpen } = useApplication();
  const width = window.innerWidth > 768 ? 300 : 'calc(100vw - 20px)';

  const errorStyle = {
    background: 'var(--error)',
    color: 'var(--surface-bg)',
  };

  const successStyle = {
    background: 'var(--surface-bg)',
    color: 'var(--primary-text)',
  };

  useEffect(() => {
    if (notifications.length) {
      forEach(notifications, ({ description, type, title }) => {
        const errorText = isArray(description)
          ? map(description, (item) => <p>{item}</p>)
          : description;

        notification.open({
          description: errorText,
          placement: 'top',
          duration: 3,
          message: title,
          closeIcon: <></>,
          style: {
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translate(-50%)',
            maxWidth: width,
            margin: 0,
            fontSize: 14,
            lineHeight: 'normal',
            textAlign: 'center',
            fontWeight: 'bold',
            border: '1px solid var(--surface-bg)',
            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
            ...(type === 'success' ? successStyle : errorStyle),
          },
        });
      });

      window.addEventListener('change', () => notification.destroy(), {
        once: true,
      });

      deleteNotifications();
    }
  }, [notifications.length]);

  useEffect(() => {
    notification.destroy();
  }, [isMenuOpen]);

  return children;
};

export default Notifications;
