import styled from 'styled-components';
import Layout from 'antd/lib/layout';

import device from '@helpers/breakpoints';

export const HeaderST = styled.div`
  background-color: var(--surface-bg);
  padding: 0 15px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: inset 0 -1px 0 0 #f0f0f0;
  overflow-y: visible;

  &.cashier {
    padding: 30px 40px;
    min-height: 90px;
    background-color: var(--primary);
    box-shadow: none;

    @media ${device.tablet} {
      padding: 10px;
      min-height: 54px;
    }
  }

  @media ${device.tablet} {
    padding: 8px 10px;
    height: min-content;

    &.cashier {
      min-height: 70px;
    }
  }
`;

export const LayoutWrapper = styled(Layout)`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const MainSection = styled.div<{ isMenuOpen?: boolean }>`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    overflow: ${({ isMenuOpen }) => (isMenuOpen ? 'hidden' : 'auto')};
  }
`;

export const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media ${device.tablet} {
    height: auto;
    min-height: 100vh;
    /* stylelint-disable-next-line */
    min-height: -webkit-fill-available;
  }

  @media print {
    padding: 25px 0;
    background-color: var(--surface-bg);

    & div {
      padding: 0;
    }

    .header-content,
    .ant-pagination,
    .panel > div:first-child,
    .ant-table-column-sorter,
    .ant-table-filter-trigger,
    .anticon-copy,
    .detailpage-title-print,
    .detailpage-subtitle-print span,
    .ant-menu,
    .ant-table-selection-col,
    .ant-table-selection-column,
    .ant-table-cell.input-group,
    button {
      display: none;
    }

    table th,
    table td {
      padding: 1px 5px;
      font-family: Roboto;
      font-size: 10px;
      line-height: 1.57;
    }

    div.panel {
      background-color: var(--surface-bg);
    }

    th:has(.no-print) {
      display: none;

      ::before {
        display: none;
      }
    }

    td:has(.no-print) {
      display: none;
    }

    h1,
    .detailpage-subtitle-print {
      display: block;
      margin-bottom: 20px;
      font-size: 32px;
    }
  }
`;

export default HeaderST;
