import React from 'react';
import Link from 'next/link';
import intl from 'react-intl-universal';

import RoutesMapper from '../../constants/routes';

import * as S from './index.styled';

const { TERMS_CONDITIONS, IMPRESSUM, PRIVACY_POLICY } = RoutesMapper;

const StatementLinks = ({
  isDashboard,
}: {
  isDashboard?: boolean;
}): JSX.Element => {
  return (
    <S.Wrapper className="footer-page" isDashboard={isDashboard}>
      <S.Link
        href={TERMS_CONDITIONS}
        target="_blank"
        rel="noopener noreferrer"
        isDashboard={isDashboard}
      >
        {intl.get('login.terms_conditions')}
      </S.Link>

      <Link href={IMPRESSUM}>
        <S.Link href={IMPRESSUM} isDashboard={isDashboard} as="div">
          {intl.get('login.imprint')}
        </S.Link>
      </Link>

      <S.Link
        href={PRIVACY_POLICY}
        target="_blank"
        rel="noopener noreferrer"
        isDashboard={isDashboard}
      >
        {intl.get('login.privacy_policy')}
      </S.Link>
    </S.Wrapper>
  );
};

StatementLinks.defaultProps = {
  isDashboard: false,
};

export default StatementLinks;
