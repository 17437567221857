export enum ButtonType {
  primary = 'primary',
  light = 'light',
  grey = 'grey',
  fullGray = 'fullGray',
  yellow = 'yellow',
  secondary = 'secondary',
}

export interface IBasicButton {
  children: JSX.Element | string;
  onClick: () => void;
  disabled?: boolean;
  type?: ButtonType;
  className?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  iconbtn?: boolean;
}

export type TBasicButton = (props: IBasicButton) => JSX.Element;
