import React from 'react';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';

import RoutesMapper from '../../../constants/routes';
import BasicButton from '../buttonBasic/BasicButton';
import { ButtonType } from '../buttonBasic/BasicButton.types';
import Image from '../customImage/Image';

import * as S from './CustomErrorPage.styled';

const { ADMIN_OVERVIEW } = RoutesMapper;

const CustomErrorPage = ({ errorCode }: { errorCode: number }): JSX.Element => {
  const { back, push } = useRouter();

  const onHome = (): void => {
    push(ADMIN_OVERVIEW);
  };

  const onBack = (): void => {
    back();
  };

  return (
    <S.Root>
      <S.ImageWrapper>
        <Image src={`/images/${errorCode}.svg`} />
      </S.ImageWrapper>
      <S.Title>{errorCode}</S.Title>
      <S.Message>{intl.getHTML(`error_page.${errorCode}_message`)}</S.Message>
      <S.ButtonWrapper>
        <S.HomeButton>
          <BasicButton type={ButtonType.light} onClick={onHome}>
            {intl.getHTML('error_page.to_home')}
          </BasicButton>
        </S.HomeButton>
        <S.BackButton>
          <BasicButton type={ButtonType.fullGray} onClick={onBack}>
            {intl.getHTML('error_page.back')}
          </BasicButton>
        </S.BackButton>
      </S.ButtonWrapper>
    </S.Root>
  );
};

export default CustomErrorPage;
