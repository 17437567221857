import styled from 'styled-components';

import device from '@helpers/breakpoints';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  @media ${device.tablet} {
    align-items: flex-start;
  }
`;

export const Inner = styled.div`
  display: flex;
  width: 100%;

  @media ${device.tablet} {
    gap: 8px;
    flex-direction: column;
    align-items: center;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: rgb(0 0 0 / 85%);
  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
  }

  @media ${device.tablet} {
    margin-top: 5px;
  }
`;

export const LogoWrapper = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
    flex-grow: 1;
    margin-right: 25px;

    a {
      padding: 0;
    }
  }
`;

export const TranslucentBlock = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 80%);
  top: 0;
  margin-left: -10px;
  padding: 15px 12px;
  z-index: 5;
  display: flex;

  @media ${device.laptop} {
    display: none;
  }
`;

export const WhiteIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: white;
  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const SupportWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #262626;

  a {
    color: var(--primary);
    font-weight: 600;
    margin-left: 4px;
  }

  @media ${device.laptop} {
    margin-left: 15%;
  }
`;
