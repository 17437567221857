import styled from 'styled-components';

import device from '@helpers/breakpoints';

export const Wrapper = styled.div<{ isDashboard?: boolean }>`
  ${({ isDashboard }) =>
    isDashboard
      ? 'padding: 0 25px 25px 25px;'
      : 'padding: 0 20px; margin-top: -40px;'}

  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${device.tablet} {
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
    margin-top: 0;
    padding: ${({ isDashboard }) => (isDashboard ? '10px 5px;' : '5px')};
  }

  @media print {
    display: none;
  }
`;

export const Link = styled.a<{ isDashboard?: boolean }>`
  color: ${({ isDashboard }) =>
    isDashboard ? 'var(--primary-text)' : 'var(--primary-button)'};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  margin-right: 15px !important;

  :hover {
    color: ${({ isDashboard }) =>
      isDashboard ? 'var(--primary-text)' : 'var(--primary-button)'};
  }

  @media ${device.tablet} {
    font-size: 14px;
  }
`;
