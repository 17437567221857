import styled from 'styled-components';

import device from '@helpers/breakpoints';

const btnStyles = (type: string) => {
  if (type === 'primary') {
    return `
      height: 38.8px;
      background-color: var(--primary);
      border: 1px solid var(--primary);
      border-radius: 2px;
      color: var(--surface-bg);
      font-size: 16px;
      line-height: 1.5;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
    `;
  }

  if (type === 'secondary') {
    return `
      height: 36px;
      background-color: transparent;
      border: 1px solid var(--surface-bg);
      border-radius: 2px;
      color: var(--surface-bg);
      font-size: 14px;
      line-height: 1.5;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
    `;
  }

  if (type === 'grey') {
    return `
      height: 38.8px;
      background-color: var(--surface-bg);
      border: 1px solid var(--disabled);
      border-radius: 2px;
      color: var(--primary-text);
      font-size: 16px;
      line-height: 1.5;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
    `;
  }

  if (type === 'light') {
    return `
      height: 32px;
      background-color: var(--surface-bg);
      border: 1px solid var(--primary-text-light);
      border-radius: 2px;
      color: var(--primary-text-light);
      font-size: 14px;
    `;
  }

  if (type === 'fullGray') {
    return `
      height: 32px;
      background-color: var(--surface-alt-bg);
      border: 1px solid var(--disabled);
      border-radius: 2px;
      color: var(--disabled);
      font-size: 14px;
    `;
  }

  return `
    height: 42px;
    background-color: var(--primary-button);
    border: 1px solid var(--primary-button);
    border-radius: 5px;
    color: var(--primary-text);
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  `;
};

export const BasicButtonST = styled.div<{
  type: string;
  iconbtn?: boolean;
}>`
  button {
    ${({ type }) => btnStyles(type)}

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 16px;
    width: ${({ iconbtn }) => (iconbtn ? '48px' : '100%')};
    user-select: none;
    cursor: pointer;
    outline: 0;
    transition: none;
    font-family: Roboto;

    span[role='img'] {
      display: flex;
    }

    &:disabled,
    &[disabled]:active,
    &[disabled]:hover,
    &[disabled]:focus {
      cursor: not-allowed;
      transition: none;
    }

    &:hover,
    &:focus,
    &:active {
      ${({ type }) => btnStyles(type)}

      transition: none;
    }

    @media ${device.tablet} {
      padding: 0 20px;
    }
  }
`;

export const Text = styled.div`
  margin-left: 5px;
`;
