import numeral from './numeral';

const formatEuroAmount = (
  amount: number,
  reverse?: boolean,
  format?: string,
): string => {
  const amountFormat = format || '0,0.00';

  return reverse
    ? `€ ${numeral(amount).format(amountFormat)}`
    : `${numeral(amount).format(amountFormat)} €`;
};

export default formatEuroAmount;
