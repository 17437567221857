import React from 'react';
import intl from 'react-intl-universal';
import Link from 'next/link';

import formatEuroAmount from '@helpers/formatEuroAmount';

import { useUserState } from '@providers/user';

import RoutesMapper from '../../../constants/routes';
import UserTypes from '../../../constants/userTypes';

import * as S from './HeaderBalanceLabel.styled';

const { ADMIN_BALANCE } = RoutesMapper;
const { PARTNER } = UserTypes;

const HeaderBalanceLabel = (): JSX.Element => {
  const { data: user } = useUserState();

  return user?.type === PARTNER ? (
    <S.LabelHeader>
      <b>
        {intl.get('balance.current_balance')}
        {formatEuroAmount(Number(user?.balance), true)}
      </b>
      <Link href={ADMIN_BALANCE}>
        <S.CreditButton>{intl.get('balance.add_credit')}</S.CreditButton>
      </Link>
    </S.LabelHeader>
  ) : null;
};

export default HeaderBalanceLabel;
