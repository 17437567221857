import React, { useEffect } from 'react';
import Layout from 'antd/lib/layout';

import Notifications from '@components/Notifications';
import Sidebar from '@components/common/sidebar/Sidebar';
import RestrictedBlock from '@components/common/blockRestricted/RestrictedBlock';
import StatementLinks from '@components/statementsLink';

import { isDesktopView } from '@helpers/baseUtils';

import { useApplication } from '@providers/applicationContext';
import { useUserState } from '@providers/user';
import toggleStatusSidebar from '@providers/applicationContext/actions/toggleStatusSidebar';

import UserTypes from '../../../constants/userTypes';
import CashierDashboardHeader from '../cashierDashboardHeader/CashierDashboardHeader';
import DashboardHeader from '../dashboardHeader/DashboardHeader';

import * as S from './DashboardWrapper.styled';
import PropTypes from './DashboardWrapper.types';

const { USER, PARTNER, CASHIER } = UserTypes;

const DashboardWrapper = ({ children }: PropTypes): JSX.Element => {
  const { isMenuOpen } = useApplication();
  const { data: user } = useUserState();
  const isDesktop = isDesktopView();

  useEffect(() => {
    if (user?.type !== CASHIER && !isMenuOpen && isDesktop) {
      toggleStatusSidebar();
    }
  }, []);

  useEffect(() => {
    if (user?.type === CASHIER && isMenuOpen) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      document.body.style.overflow = 'hidden';
    } else if (
      (user?.type === USER || user?.type === PARTNER) &&
      isMenuOpen &&
      !isDesktop
    ) {
      document.body.style.overflow = 'hidden';
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  const renderHeader = (): JSX.Element => (
    <>
      <RestrictedBlock userRole={[CASHIER]}>
        <S.HeaderST className="header-content cashier">
          <CashierDashboardHeader disabled={false} />
        </S.HeaderST>
      </RestrictedBlock>

      <RestrictedBlock userRole={[USER, PARTNER]}>
        <S.HeaderST className="header-content">
          <DashboardHeader />
        </S.HeaderST>
      </RestrictedBlock>
    </>
  );

  return (
    <Layout hasSider className="admin-wrapper">
      <S.LayoutWrapper>
        {isMenuOpen && <Sidebar />}
        <S.MainSection isMenuOpen={isMenuOpen}>
          <S.ScreenWrapper>
            {renderHeader()}

            <Notifications>{children}</Notifications>
          </S.ScreenWrapper>
          <StatementLinks isDashboard />
        </S.MainSection>
      </S.LayoutWrapper>
    </Layout>
  );
};

export default DashboardWrapper;
